import {
    ref, Ref, reactive
} from 'vue';
import { propertyBaseRouter, propertySubRouter, propertyOldCommunitySubRouter } from '@/router/data';
import {
    systemConfig, accountType, account as accountRequestData, community
} from '@/data';
import { ChangeWordList } from '@/methods/setText';
import { sysConfig } from '@/data/src/system-config';

const activeCommunity = reactive({
    ID: '0',
    label: WordList.ProperAllTextListOfCommunities
});

const getUserOperaData = () => {
    const userOperaList = ref([{
        label: WordList.TabelChangePwTitle,
        key: 'password'
    }, {
        label: WordList.OrderBillingInformation,
        key: 'bill'
    }]);

    const fileList: Ref<Array<string>> = ref([]);

    systemConfig.getToolMenu((data: systemConfig.ToolMenuResponse) => {
        if (data.Show === 1) {
            userOperaList.value = [{
                label: WordList.TabelChangePwTitle,
                key: 'password'
            }, {
                label: WordList.OrderBillingInformation,
                key: 'bill'
            }, {
                label: WordList.KnowledgeBase,
                key: 'knowledgeBase'
            }];
        }
        fileList.value = data.List;

        // 6.6.0-修订记录V1.0.12：App上架之前由后端控制是否能Link
        systemConfig.getSysConfig((res) => {
            sysConfig.IsPMLink = res.IsPMLink;
            if (sysConfig.IsPMLink === 1) {
                userOperaList.value.push({
                    label: WordList.LinkSite,
                    key: 'link-site'
                });
            }
        });
    });

    return { userOperaList, fileList };
};

const getMenuList = (changeMode: string | number = '0', projectType: ProjectType | false = false) => {
    const newProjectMenu = [
        {
            label: WordList.NavInHtmlMenuDashboard,
            name: `${propertyBaseRouter}_${propertySubRouter.home}`,
            path: `/${propertyBaseRouter}/${propertySubRouter.home}`,
            icon: 'el-icon-my-dashboard'
        }, {
            label: ChangeWordList.ApartmentOrDepartment,
            name: `${propertyBaseRouter}_${propertySubRouter.apartment}|${propertyBaseRouter}_${propertySubRouter.apartmentInfo}`,
            path: `/${propertyBaseRouter}/${propertySubRouter.apartment}`,
            icon: 'el-icon-my-residents2'
        }, {
            label: ChangeWordList.ResidentOrPeople,
            name: `${propertyBaseRouter}_${propertySubRouter.resident}|${propertyBaseRouter}_${propertySubRouter.residentSet}|${propertyBaseRouter}_${
                propertySubRouter.residentInfo}|${propertyBaseRouter}_${propertySubRouter.residentSetAccessGroup}|${propertyBaseRouter}_${
                propertySubRouter.residentSetInfo}|${propertyBaseRouter}_${propertySubRouter.residentSetSelfAccess}`,
            path: `/${propertyBaseRouter}/${propertySubRouter.resident}`,
            icon: 'el-icon-my-ai-connection'
        }, {
            label: ChangeWordList.PMPersonnelToVisitor,
            name: `${propertyBaseRouter}_${propertySubRouter.personnel}|${propertyBaseRouter}_${propertySubRouter.tempKeyInfo}|${
                propertyBaseRouter}_${propertySubRouter.personnelHandle}`,
            path: `/${propertyBaseRouter}/${propertySubRouter.personnel}`,
            icon: 'el-icon-my-community-personnel'
        }, {
            label: WordList.ProperAllTextAccessGroup,
            name: `${propertyBaseRouter}_${propertySubRouter.access}|${propertyBaseRouter}_${propertySubRouter.setAccess}|${
                propertyBaseRouter}_${propertySubRouter.accessInfo}`,
            path: `/${propertyBaseRouter}/${propertySubRouter.access}`,
            icon: 'el-icon-my-access-permission'
        }, {
            label: WordList.ProperAllTextLogs,
            name: `${propertyBaseRouter}_${propertySubRouter.log}`,
            path: `/${propertyBaseRouter}/${propertySubRouter.log}`,
            icon: 'el-icon-my-logs'
        }, {
            label: WordList.AlarmRecords,
            name: `${propertyBaseRouter}_${propertySubRouter.alarmRecoeds}`,
            path: `/${propertyBaseRouter}/${propertySubRouter.alarmRecoeds}`,
            icon: 'el-icon-my-logs'
        }, {
            label: WordList.ArmingAlarm,
            name: `${propertyBaseRouter}_${propertySubRouter.armingAlarm}`,
            path: `/${propertyBaseRouter}/${propertySubRouter.armingAlarm}`,
            icon: 'el-icon-my-logs'
        },
        {
            label: WordList.ProperAllTextLibrary,
            name: `${propertyBaseRouter}_${propertySubRouter.library}`,
            path: `/${propertyBaseRouter}/${propertySubRouter.library}`,
            icon: 'el-icon-my-library'
        }, {
            label: WordList.ProperAllTextGroupMessages,
            name: `${propertyBaseRouter}_${propertySubRouter.message}|${propertyBaseRouter}_${propertySubRouter.messageInfo}|${
                propertyBaseRouter}_${propertySubRouter.messageAdd}`,
            path: `/${propertyBaseRouter}/${propertySubRouter.message}`,
            icon: 'el-icon-my-group-message'
        }, {
            label: WordList.OrderDevice,
            name: `${propertyBaseRouter}_${propertySubRouter.device}|${propertyBaseRouter}_${propertySubRouter.deviceInfo}|${
                propertyBaseRouter}_${propertySubRouter.screenSaver}|${propertyBaseRouter}_${propertySubRouter.setScreenSaver}`,
            path: `/${propertyBaseRouter}/${propertySubRouter.device}`,
            icon: 'el-icon-my-devices2'
        }, {
            label: WordList.NavInHtmlMenuPurchase,
            name: `${propertyBaseRouter}_${propertySubRouter.subscription}`,
            path: `/${propertyBaseRouter}/${propertySubRouter.subscription}`,
            icon: 'el-icon-my-subscription2'
        }, {
            label: WordList.NavInHtmlMenuOrder,
            name: `${propertyBaseRouter}_${propertySubRouter.payment}|${propertyBaseRouter}_${propertySubRouter.paymentInfo}`,
            path: `/${propertyBaseRouter}/${propertySubRouter.payment}`,
            icon: 'el-icon-my-payments'
        }, {
            label: WordList.ProperAllTextSetting,
            name: `${propertyBaseRouter}_${propertySubRouter.setting}`,
            path: `/${propertyBaseRouter}/${propertySubRouter.setting}`,
            icon: 'el-icon-my-setting2'
        }, {
            label: WordList.LoginUserAgreement,
            name: `${propertyBaseRouter}_${propertySubRouter.userAgreement}`,
            path: `/${propertyBaseRouter}/${propertySubRouter.userAgreement}`,
            icon: 'el-icon-my-setting2',
            children: [
                {
                    label: WordList.LoginPrivacyPolicy,
                    name: `${propertyBaseRouter}_${propertySubRouter.privacyPolicy}`,
                    path: `/${propertyBaseRouter}/${propertySubRouter.privacyPolicy}`,
                    icon: 'el-icon-my-setting2'
                }, {
                    label: WordList.LoginUserAgreement,
                    name: `${propertyBaseRouter}_${propertySubRouter.termsOfService}`,
                    path: `/${propertyBaseRouter}/${propertySubRouter.termsOfService}`,
                    icon: 'el-icon-my-setting2'
                }
            ]
        }];

    const oldCommunityMenu = [
        {
            label: WordList.NavInHtmlMenuDashboard,
            name: `${propertyBaseRouter}_${propertySubRouter.home}`,
            path: `/${propertyBaseRouter}/${propertySubRouter.home}`,
            icon: 'el-icon-my-dashboard'
        },
        {
            label: WordList.RDeviceResidents,
            name: `${propertyBaseRouter}_${propertyOldCommunitySubRouter.oldResident}|${
                propertyBaseRouter}_${propertyOldCommunitySubRouter.oldSetResident}|${
                propertyBaseRouter}_${propertyOldCommunitySubRouter.oldResidentInfo}`,
            path: `/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldResident}`,
            icon: 'el-icon-my-residents'
        },
        {
            label: WordList.ProperAllTextLogs,
            name: `${propertyBaseRouter}_${propertySubRouter.log}`,
            path: `/${propertyBaseRouter}/${propertySubRouter.log}`,
            icon: 'el-icon-my-logs'
        },
        {
            label: WordList.ProperAllTextGroupMessages,
            name: `${propertyBaseRouter}_${propertySubRouter.message}|${propertyBaseRouter}_${propertySubRouter.messageInfo}|${
                propertyBaseRouter}_${propertySubRouter.messageAdd}`,
            path: `/${propertyBaseRouter}/${propertySubRouter.message}`,
            icon: 'el-icon-my-group-message'
        }, {
            label: WordList.OrderDevice,
            name: `${propertyBaseRouter}_${propertySubRouter.device}|${propertyBaseRouter}_${propertySubRouter.deviceInfo}`,
            path: `/${propertyBaseRouter}/${propertySubRouter.device}`,
            icon: 'el-icon-my-devices'
        }, {
            label: WordList.ProperAllTextResidentAuth,
            name: `${propertyBaseRouter}_${propertyOldCommunitySubRouter.oldResidentAuth}|${
                propertyBaseRouter}_${propertyOldCommunitySubRouter.oldSetResidentAuth}`,
            path: `/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldResidentAuth}`,
            icon: 'el-icon-my-residents'
        }, {
            label: WordList.DarshbordTmp,
            name: `${propertyBaseRouter}_${propertyOldCommunitySubRouter.oldTempKeys}|${
                propertyBaseRouter}_${propertyOldCommunitySubRouter.oldTempKeyNew}|${propertyBaseRouter}_${
                propertyOldCommunitySubRouter.oldTempKeyInfo}`,
            path: `/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldTempKeys}`,
            icon: 'el-icon-my-tempkey'
        }, {
            label: WordList.DeliveryDeliveryAuth,
            name: `${propertyBaseRouter}_${propertyOldCommunitySubRouter.oldDeliveryAuth}|${
                propertyBaseRouter}_${propertyOldCommunitySubRouter.oldAddDeliveryAuth}`,
            path: `/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldDeliveryAuth}`,
            icon: 'el-icon-my-deliveryauth'
        }, {
            label: WordList.DeliveryStaffAuth,
            name: `${propertyBaseRouter}_${propertyOldCommunitySubRouter.oldStaffAuth}|${
                propertyBaseRouter}_${propertyOldCommunitySubRouter.oldSetStaffAuth}`,
            path: `/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldStaffAuth}`,
            icon: 'el-icon-my-staffauth'
        }, {
            label: WordList.NavInHtmlMenuPurchase,
            name: `${propertyBaseRouter}_${propertySubRouter.subscription}`,
            path: `/${propertyBaseRouter}/${propertySubRouter.subscription}`,
            icon: 'el-icon-my-subscription2'
        }, {
            label: WordList.NavInHtmlMenuOrder,
            name: `${propertyBaseRouter}_${propertySubRouter.payment}|${propertyBaseRouter}_${propertySubRouter.paymentInfo}`,
            path: `/${propertyBaseRouter}/${propertySubRouter.payment}`,
            icon: 'el-icon-my-payments'
        }, {
            label: WordList.ProperAllTextSetting,
            name: `${propertyBaseRouter}_${propertySubRouter.setting}`,
            path: `/${propertyBaseRouter}/${propertySubRouter.setting}`,
            icon: 'el-icon-my-setting2'
        }
    ];

    let menu = (!community.isNew.value && projectType === 'community') ? oldCommunityMenu : newProjectMenu;
    if (projectType === 'community') {
        menu = menu.filter((item) => (item.path !== `/${propertyBaseRouter}/${propertySubRouter.armingAlarm}`));
    }
    // 办公不显示付费板块
    if (Number(changeMode) === 1 || projectType === 'office') {
        menu = menu.filter((item) => (item.path !== `/${propertyBaseRouter}/${propertySubRouter.subscription}`
            && item.path !== `/${propertyBaseRouter}/${propertySubRouter.payment}`));
    }
    if (projectType === 'office') {
        menu = menu.filter((item) => (item.path !== `/${propertyBaseRouter}/${propertySubRouter.alarmRecoeds}`));
    }

    return menu;
};

const checkNameForProject = (data: Array<accountType.GetPropertyCommunityItem>) => {
    /* eslint-disable no-param-reassign */
    data.forEach((outerItem) => {
        data.forEach((innerItem) => {
            if (innerItem.Location === outerItem.Location && innerItem.Grade !== outerItem.Grade) {
                outerItem.Location = `${outerItem.Location}(${Number(outerItem.Grade) === accountRequestData.officeRole
                    ? WordList.ProperAllOffice : WordList.ProperAllCommunity})`;
                innerItem.Location = `${innerItem.Location}(${Number(innerItem.Grade) === accountRequestData.officeRole
                    ? WordList.ProperAllOffice : WordList.ProperAllCommunity})`;
            }
        });
    });
    return data;
};

interface ControlType {
    community: boolean;
    account: boolean;
}

const controlPop = () => {
    const controlPopVal: Ref<ControlType> = ref({
        community: false,
        account: false
    });
    const hideAllPop = () => {
        controlPopVal.value.account = false;
        controlPopVal.value.community = false;
    };
    const hideOtherPop = (event: boolean, type: keyof ControlType) => {
        controlPopVal.value[type] = event;
        if (event) {
            Object.keys(controlPopVal.value).forEach((key) => {
                const itemKey = key as keyof ControlType;
                if (itemKey !== type) {
                    controlPopVal.value[itemKey] = false;
                }
            });
        }
    };
    return {
        hideAllPop,
        hideOtherPop,
        controlPopVal
    };
};

export default null;
export {
    activeCommunity,
    getUserOperaData,
    getMenuList,
    checkNameForProject,
    controlPop
};
